.seuil-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .seuil-input {
    padding: 10px;
    font-size: 16px;
    width: 300px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #ccc;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  
  .confirm-btn, .back-btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .confirm-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .back-btn {
    background-color: #f44336;
    color: white;
  }
  
  .confirm-btn:hover {
    background-color: #45a049;
  }
  
  .back-btn:hover {
    background-color: #e53935;
  }
  

  