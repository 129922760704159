/* Sidebar.css */
.sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Toujours visible sur le bureau */
    z-index: 1000;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-heading {
    font-size: 1.5rem;
    padding: 1rem;
    background-color: #e9ecef;
  }
  
  .list-group-item {
    padding: 1rem;
    border: none;
  }
  
  .menu-toggle {
    position: fixed;
    top: 15px;
    left: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1100;
    display: none; /* Cacher par défaut */
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: 100vh;
      transform: translateX(-100%); /* Cacher le sidebar par défaut */
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .menu-toggle {
      display: block; /* Afficher sur petits écrans */
    }
  }
  