#wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    
  }
  
  #sidebar-wrapper {
    min-width: 250px;
    max-width: 250px;
    
    
  }
  
  #page-content-wrapper {
    width: 100%;
    
  }
  
  .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  
  .list-group-item {
    cursor: pointer;
  }
  
  