.custom-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Deux colonnes égales */
  gap: 20px; /* Espacement entre les colonnes */
  max-width: 100%; /* S'assure que le formulaire utilise toute la largeur disponible */
}



.form-group-left,
.form-group-right {
  display: flex;
  flex-direction: column; /* Aligne les éléments verticalement */
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; /* Espacement entre les groupes de champs */
  text-align: right; /* Aligne le contenu à droite */
}

.input-group label {
  display: block; /* Affiche les labels comme blocs */
  font-weight: bold;
  margin-bottom: 5px;
  text-align: right; /* Aligne le texte à droite */
}

.input-group input,
.input-group select {
  display: block; /* Affiche les inputs et selects comme blocs */
  width: 100%; /* Assure que les inputs et selects prennent toute la largeur disponible */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: right; /* Aligne le texte à droite dans les champs d'entrée */
  margin-bottom: 15px; /* Espacement en bas */
}

.buttoncontainer {
  display: flex;               /* Utilise Flexbox pour organiser les boutons */
  justify-content: center;     /* Centre les boutons horizontalement */
  align-items: center;         /* Centre verticalement (si nécessaire) */
  gap: 10px;                   /* Espace entre les boutons */
  margin-top: 20px;            /* Espace au-dessus */
}

.backbtn {
  padding: 10px 20px !important;
  border-radius: 5px !important;
  background-color: #e03a3a !important;
  border: none !important;
  cursor: pointer !important;
}

.submitbutton {
  padding: 10px 20px;          /* Ajoute du padding pour une meilleure apparence */
  border-radius: 5px;          /* Coins arrondis */
  background-color: #23a354;   /* Couleur de fond */
  color: white;                /* Couleur du texte */
  border: none;                /* Pas de bordure */
  cursor: pointer;             /* Change le curseur pour indiquer que c'est cliquable */
  font-size: 16px;             /* Taille de la police */
}

.submitbutton:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
}

.backbtn {
  padding: 10px 20px;          /* Ajoute du padding pour une meilleure apparence */
  border-radius: 5px;          /* Coins arrondis */
  background-color: #d14646;   /* Couleur de fond */
  border: none;                /* Pas de bordure */
  cursor: pointer;             /* Change le curseur pour indiquer que c'est cliquable */
}

.backbtn:hover {
  background-color: #e0e0e0; /* Couleur de fond au survol */
}

.centered-form-container {
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  height: 100vh; /* Assure que le conteneur prend toute la hauteur de la page */
}

.centered-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Deux colonnes égales */
  gap: 20px; /* Espacement entre les colonnes */
  max-width: 600px; /* Limite la largeur maximale du formulaire */
  width: 100%; /* Assure que le formulaire prenne toute la largeur disponible jusqu'à 600px */
  background-color: #f9f9f9; /* Fond léger pour différencier le formulaire */
  padding: 20px; /* Espacement interne pour un meilleur design */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère pour un effet surélevé */
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.5s ease;
}

.popup p {
  margin: 0;
  font-size: 16px;
}
/* Superposition */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay avec transparence */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Priorité d'affichage */
}

/* Contenu du modal */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 300px;
}

.modal-content button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0056b3;
}
.modalbutton {
  background-color: #f44336; /* Rouge pour "Non" */
  color: white;
  margin-left: 10px; /* Espacement entre les boutons */
}