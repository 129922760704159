/* Profile.css */
.table-container {
    padding: 20px;
    padding-right: 30px;
    padding-left: 250px;
    margin: 0 auto;
    max-width: 100%; /* Ajusté pour les écrans plus petits */
    overflow-x: auto; /* Ajouter le défilement horizontal si nécessaire */
    direction: rtl; /* Pour aligner le texte à droite pour les langues RTL */
  }
  
  .table-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 12px;
    text-align: right; /* Aligner le texte à droite */
  }
  
  thead {
    background-color: #007bff;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  
  th {
    font-weight: bold;
  }
  
  .btn-edit, .btn-delete {
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    border-radius: 4px;
  }
  .btn-pdf {
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    color: white;
    font-size: 11px;
    border-radius: 4px;
    width: 55px;
  }
  .btn-edit {
    background-color: #007bff; /* Couleur bleue pour le bouton Modifier */
  }
  .btn-pdf {
    background-color:  #084918; 
  }
  
  .btn-edit:hover {
    background-color: #0056b3; /* Couleur bleue foncée au survol */
  }
  
  .btn-delete {
    background-color: #dc3545; /* Couleur rouge pour le bouton Supprimer */
  }
  
  .btn-delete:hover {
    background-color: #c82333; /* Couleur rouge foncé au survol */
  }
  .btn-pdf:hover {
    background-color:  #084918; 
  }
  
  /* Styles additionnels pour les petits écrans */
  @media (max-width: 768px) {
    .table-container {
      padding-right: 10px; /* Réduire le padding pour les petits écrans */
    }
  
    table {
      font-size: 12px; /* Réduire la taille de la police pour les petits écrans */
    }
  
    .btn-edit, .btn-delete, .btn-pdf {
      padding: 6px 12px; /* Ajuster la taille des boutons pour les petits écrans */
    }
  
    th, td {
      padding: 8px; /* Réduire le padding des cellules pour plus de place */
    }
  }
  