.etablissement-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  
  .add-button1, .submitbutton, .modalbutton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
  }
  .back-btn{
     padding: 10px 20px;
    margin: 10px 0;
  }
  .add-button1:hover, .submit-button:hover, .modalbutton:hover {
    background-color: #45a049;
  }
  
  .etablissement-form {
    display: flex;
    flex-direction: column;
  }
  
  .etablissement-form label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .etablissement-form input, .etablissement-form select {
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .button-container1 {
    display: flex;              /* Utiliser Flexbox pour aligner les boutons côte à côte */
    gap: 5px;                 /* Espace entre les boutons */
    justify-content: center;    /* Centrer les boutons dans le conteneur */
    margin-top: 20px;          /* Espace au-dessus du conteneur si nécessaire */
  
}

.edit-button, .delete-button {
    width: 80px;              
    padding: 10px;             
    border: none;              
    border-radius: 5px;       
    color: white;              
    font-size: 12px;           
    cursor: pointer;           
    transition: background-color 0.3s; 
    margin: 0;
}

.edit-button {
    background-color: #5BC0DE; 
}

.delete-button {
    background-color: #D9524E; 
}

.edit-button:hover {
    background-color: #4BA4C9; 
}

.delete-button:hover {
    background-color: #C63A38; 
}
.etablissement-form {
    display: flex;
    flex-direction: column; /* Permet aux labels et inputs d'être empilés */
}

label {
    display: flex; /* Utilisation de flexbox pour chaque label */
    justify-content: space-between; /* Espacement entre le label et le champ */
    align-items: center; /* Centrer verticalement les items */
    margin-bottom: 10px; /* Espacement entre les lignes */
}

label select,
label input {
    flex: 1; /* Permet aux champs de prendre toute la largeur restante */
    margin-left: 10px; /* Espacement à gauche du champ */
    padding: 5px; /* Padding pour le champ */
}
.etablissement-form {
    display: flex;
    flex-direction: column; /* Pour mettre les éléments les uns en dessous des autres */
  }
  
  .form-group {
    margin-bottom: 15px; /* Ajoute de l'espace entre les groupes */
  }
  
  label {
    display: block; /* Force le label à être sur une nouvelle ligne */
    margin-bottom: 5px; /* Espace entre le label et le champ */
  }
  
  input,
  select {
    width: 100%; /* Largeur complète pour les inputs et selects */
    padding: 8px; /* Ajoute du padding pour un meilleur rendu */
    border: 1px solid #ccc; /* Bordure standard */
    border-radius: 4px; /* Coins arrondis */
  }
/* Ajoutez ce CSS à votre feuille de style */
.ReactModal__Content {
    overflow-y: auto; /* Permet le défilement vertical */
    max-height: 80vh; /* Limite la hauteur du modal à 80% de la fenêtre */
  }
  .confirm-button, .modalbutton {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .confirm-button {
    background-color: #4CAF50; /* Vert pour "Oui" */
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #45a049; /* Plus foncé au survol */
    transform: scale(1.05); /* Agrandissement au survol */
  }
  
  .modalbutton {
    background-color: #f44336; /* Rouge pour "Non" */
    color: white;
    margin-left: 10px; /* Espacement entre les boutons */
  }
  
  .modalbutton:hover {
    background-color: #e53935; /* Plus foncé au survol */
    transform: scale(1.05); /* Agrandissement au survol */
  }


  