.uploader-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1475cf;
    height: 300px;
    width: 500px;
    cursor: pointer;
    border-radius: 5px;
    margin: auto;
}

.uploaded-row {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #e9f0ff;
}

.upload-content {
    display: flex;
    align-items: center;
}



.submit-button {
    display: block; /* Assure que le bouton est affiché en bloc */
    width: 150px; /* Ajuste la largeur du bouton */
    padding: 10px; /* Ajoute un peu de padding */
    margin: 20px auto; /* Centre le bouton horizontalement */
    background-color: #1475cf; /* Couleur de fond */
    color: white; /* Couleur du texte */
    border: none; /* Retire les bordures */
    border-radius: 5px; /* Arrondit les coins */
    cursor: pointer; /* Change le curseur au survol */
    font-size: 16px; /* Ajuste la taille du texte */
  }
  
  .submit-button:hover {
    background-color: #105c9f; /* Couleur de fond au survol */
  }
  

  .backbtn {
    display: block; /* Assure que le bouton est affiché en bloc */
    width: 150px; /* Ajuste la largeur du bouton */
    padding: 10px; /* Ajoute un peu de padding */
    display: flex;
    align-items: center;
    gap: 30px; /* Ajoute un espace de 10px entre l'icône et le texte */
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 150px; /* Ajuste la largeur du bouton */
    color: #ffffff; 
  }
  
  .backbtn:hover {
    background-color: #e0e0e0;
  }