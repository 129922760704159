/* InputNotes.css */
.input-notes-container {
    max-width: 600px; /* Largeur maximale */
    margin: 20px auto; /* Centre le conteneur */
    padding: 20px; /* Espacement intérieur */
    background-color: #f9f9f9; /* Couleur de fond */
    border-radius: 8px; /* Coins arrondis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre */
}

.input-notes-title {
    text-align: right; /* Aligne le texte à droite */
    margin-bottom: 15px; /* Espace sous le titre */
    color: #333; /* Couleur du texte */
}

.quill-editor {
    min-height: 200px; /* Hauteur minimale de l'éditeur */
    margin-bottom: 20px; /* Espace sous l'éditeur */
}

.submit-button {
    background-color: #4CAF50; /* Couleur de fond */
    color: white; /* Couleur du texte */
    padding: 10px 20px; /* Espacement intérieur */
    border: none; /* Pas de bordure */
    border-radius: 5px; /* Coins arrondis */
    cursor: pointer; /* Curseur pointer */
    transition: background-color 0.3s; /* Transition au survol */
}

.submit-button:hover {
    background-color: #45a049; /* Couleur au survol */
}

/* Styles pour le modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Fond semi-transparent plus sombre */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* S'assurer que le modal est au-dessus d'autres éléments */
}

.modal-content {
    background-color: #fff; /* Fond blanc */
    padding: 20px;
    border-radius: 10px; /* Coins arrondis */
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Ombre plus prononcée */
    max-width: 400px; /* Largeur maximale du modal */
    width: 80%; /* Largeur par défaut */
    animation: fadeIn 0.3s; /* Animation d'apparition */
}

.modal-content h3 {
    margin-bottom: 15px; /* Espacement sous le titre */
    font-size: 1.5rem; /* Taille de police du titre */
    color: #333; /* Couleur du texte */
}

.modal-content p {
    margin: 15px 0; /* Espacement en haut et en bas du texte */
    font-size: 1rem; /* Taille de police normale */
    color: #666; /* Couleur du texte */
}

.modal-content button {
    margin-top: 15px;
    padding: 10px 20px; /* Espacement interne */
    border: none; /* Pas de bordure */
    border-radius: 5px; /* Coins arrondis */
    background-color: #007bff; /* Couleur de fond bleu */
    color: #fff; /* Couleur du texte blanc */
    font-size: 1rem; /* Taille de police du bouton */
    cursor: pointer; /* Curseur pointeur */
    transition: background-color 0.3s; /* Transition pour l'effet de survol */
}

.modal-content button:hover {
    background-color: #0056b3; /* Couleur de fond au survol */
}

/* Animation d'apparition */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px); /* Légère translation vers le haut */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Retour à la position initiale */
    }
}



.button-container {
    display: flex; /* Utilise flexbox pour la disposition */
    justify-content: space-between; /* Espace entre les boutons */
    align-items: center; /* Centre le contenu verticalement */
    margin: 20px 0; /* Ajoute un espacement vertical si nécessaire */
}

.submit-button {
    flex-grow: 1; /* Permet au bouton d'occuper tout l'espace disponible */
    text-align: center; /* Centre le texte dans le bouton */
    margin: 0 10px; /* Espace entre les boutons */
    padding-left: 20px; /* Ajoute du padding à gauche */
    padding-right: 20px; /* Ajoute du padding à droite pour un aspect uniforme */
}