/* StudentSelector.css */

.container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: right;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

.select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.buttonContainer {
    display: flex;
    justify-content: center; /* Centre horizontalement le bouton */
    margin-bottom: 20px; /* Ajoute de l'espace en bas pour plus d'aération */
}

.button {
    padding: 12px 25px;
    border-radius: 5px;
    border: none;
    background-color: #28a745;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px 0;
}

.button:hover {
    background-color: #218838;
}

.button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.studentInfo {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 20px;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
}
.studentInfo {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.infoBoxContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.infoBox {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 30%;
    padding: 10px;
    text-align: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.infoBoxLabel {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.infoBoxValue {
    font-size: 16px;
    color: #555;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #45a049;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.navigationButtons .button {
    width: 45%;
}
.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espace entre les éléments */
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formLabel {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
}

.formSelect {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.formSelect:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.4);
    outline: none;
}

.formSelect:hover {
    border-color: #888;
}

.formContainer {
    max-width: 400px; /* Limite la largeur pour un meilleur design */
    margin: 0 auto; /* Centre le formulaire */
}
.noteTotalBox {
    padding: 15px;
    background-color: #f0f0ff; /* Un fond légèrement coloré */
    border: 1px solid #b0b0ff; /* Bordure subtilement colorée */
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
}

.noteTotalBox p {
    font-size: 1.1rem;
    color: #05050c; /* Couleur subtile pour le texte */
}

.back-btn {
    display: flex;
    align-items: center;
    gap: 10px; /* Ajoute un espace de 10px entre l'icône et le texte */
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .back-btn:hover {
    background-color: #e0e0e0;
  }
/* Modal */
/* Modal */
.modal-1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;   /* Centre horizontalement */
    align-items: center;       /* Centre verticalement */
    background-color: rgba(0, 0, 0, 0.6);  /* Fond légèrement plus foncé */
    z-index: 9999;  /* Assurez-vous que le modal est au-dessus du reste du contenu */
    animation: fadeIn 0.3s ease-out; /* Animation d'apparition */
  }
  
  /* Animation de l'apparition du modal */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Contenu du Modal */
  .modal-content-1 {
    background-color: #f8f9fa; /* Fond clair pour le contenu */
    padding: 30px;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;  /* Limite de la largeur du modal */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);  /* Ombre plus marquée */
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  /* Style du texte */
  .modal-content-1 p {
    font-size: 18px;
    color: #333; /* Couleur de texte plus douce */
    margin-bottom: 20px;
  }
  
  /* Bouton */
  button {
    background-color: #007bff; /* Bleu vif */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  /* Effet de survol sur le bouton */
  button:hover {
    background-color: #0056b3; /* Bleu plus foncé lors du survol */
  }
  
  button:focus {
    outline: none;  /* Retirer le contour du bouton lors du focus */
  }
  
  
  