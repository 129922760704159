.container1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .box1 {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
  }
  
  .section-title1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #34495e;
    margin-bottom: 1rem;
    text-align: right;
  }
  
  .input-label1 {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
    text-align: right;
  }
  
  .input-field1 {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 1rem;
    color: #2c3e50;
    background-color: #f9f9f9;
    transition: border-color 0.3s;
    text-align: right;
  }
  
  .input-field1:focus {
    border-color: #2980b9;
    outline: none;
  }
  
  input::placeholder {
    color: #7f8c8d;
  }
  
  /* Style pour les boîtes des sections */
  .original1 {
    border-left: 4px solid #3498db;
  }
  
  .future1 {
    border-left: 4px solid #e67e22;
  }
  