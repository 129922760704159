/* styles.css */
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-monthly {
    background-color: #218C91; /* Couleur pour "التقرير الشهري" */
  }
  
  .btn-periodic {
    background-color: #33B5E6; /* Couleur pour "التقرير الدوري" */
  }
  
  