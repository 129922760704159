.buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    flex-wrap: wrap;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-basis: 45%; /* Chaque groupe prend 45% de la largeur */
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.btn-left {
    background-color: #007bff;
    color: white;
}

.btn-right {
    background-color: #28a745;
    color: white;
}

.btn:hover {
    opacity: 0.9;
}

@media (max-width: 600px) {
    .buttons-container {
        flex-direction: column;
        gap: 20px;
    }

    .button-group {
        flex-basis: 100%; /* Chaque groupe prend toute la largeur sur mobile */
    }

    .btn {
        width: 100%;
    }
}

